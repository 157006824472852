import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import qw1000 from "../../assets/product_image-1.png";
import qw2000 from "../../assets/product_image-2.png";
import qw20 from "../../assets/product_image-3.png";
import HeroContent from "./mini-components/HeroContent";
import HeroSlider from "./mini-components/HeroSlider";


const Hero = () => {
  
  return (
    <>
    
   
      <Grid Container>
        <Box sx={{ display: "grid" }}>
          <Box
            sx={{
              display: { xl:"flex",lg:"flex",md: "flex", xs: "block" },
              position: "relative",
              zIndex: 2,
              marginTop: { md: "-1%", xs: "-0%" },
              backgroundColor: "#ddd",
              height: {xl:"90vh",lg:"90vh",md:"70vh",sm:"80vh",xs:"90vh"},
              padding: {
                xl: "0%",
                lg: "10%",
                md: "30px",
                sm: "30px",
                xs: "30px",
              },
              justifyContent: "center",
              alignContent: "center",
              justifyItems: "center",
              alignItems: "center",
              width: "auto",
            }}
          >
    
            <Box>
              <HeroContent />
            </Box>
            <Box>
              <HeroSlider />
            </Box>
          </Box>
          <Box
            sx={{
              backgroundColor: "#fff",
              height: {xl:"",lg:"15vh", md: "12vh",sm:"", xs: "70vh" },
              width: {xl:"",lg:"70%", md: "70%",sm:"", xs: "70vh" },
              border: "solid 1px #005096",
              borderRadius: "20px",
              justifyContent: "space-around",
              justifyItems: "center",
              alignItems: "center",
              alignContent: "center",
              display: { md: "flex", xs: "none" },
              marginX: "15%",
              marginTop: "-5%",
              padding: "20px",
              zIndex: 4,
              
            }}
          >
            <Box sx={{ marginTop: "", marginRight: "1%" }}>
              <Typography
                variant="h2"
                sx={{ fontSize: "50px", fontWeight: 500, textAlign: "center" }}
              >
                10K +
              </Typography>
              <Typography
                variant="p"
                sx={{ fontSize: "20px", fontWeight: 500 }}
              >
                Plugs Installed
              </Typography>
            </Box>
            <Box
              sx={{
                backgroundColor: "#005096",
                height: "200px",
                justifyContent: "center",
                justifyItems: "center",
                alignItems: "center",
                alignContent: "center",
                paddingX: { md: "20px", xs: "0" },
                borderEndEndRadius: "20px",
                borderEndStartRadius: "50%",
                borderStartStartRadius: "20px",
                borderStartEndRadius: "50%",
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  fontSize: { md: "60px", xs: "30px" },
                  fontWeight: 500,
                  textAlign: "center",
                  paddingTop: "40px",
                  color: "#fff",
                }}
              >
                10+
              </Typography>
              <Typography
                variant="p"
                sx={{ fontSize: "20px", fontWeight: 500, color: "#ddd" }}
              >
                Years of experince
              </Typography>
            </Box>

            <Box sx={{ marginTop: "0%" }}>
              <Typography
                variant="h2"
                sx={{ fontSize: "50px", fontWeight: 500, textAlign: "center" }}
              >
                20
              </Typography>
              <Typography
                variant="p"
                sx={{ fontSize: "20px", fontWeight: 500 }}
              >
                International Companies
              </Typography>
            </Box>
          </Box>
        </Box>
      </Grid>
    </>
  );
};

export default Hero;

import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

const faqData = [
  {
    title:
      "How can I access the water created by Aquaria’s Hydropack and Hydropack X?",
    describe:
      "Aquaria’s Hydropack and Hydropack X are whole-home, holistic backup water sources. The water produced is stored in an external storage tank, then pressurized and sent to the entire property. A simple flip of a switch enables you to supply your whole home or business with water created by Aquaria’s AWGs.",
  },
  {
    title:
      "How can I access the water created by Aquaria’s Hydropack and Hydropack X?",
    describe:
      "Aquaria’s Hydropack and Hydropack X are whole-home, holistic backup water sources. The water produced is stored in an external storage tank, then pressurized and sent to the entire property. A simple flip of a switch enables you to supply your whole home or business with water created by Aquaria’s AWGs.",
  },
];
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function CustomizedAccordions() {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
            <Typography>
              How can I access the water created by Aquaria’s Hydropack and
              Hydropack X?{" "}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Aquaria’s Hydropack and Hydropack X are whole-home, holistic
              backup water sources. The water produced is stored in an external
              storage tank, then pressurized and sent to the entire property. A
              simple flip of a switch enables you to supply your whole home or
              business with water created by Aquaria’s AWGs.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
            <Typography>
              Is water from Aquaria’s AWG safe? Does Aquaria purify and filter
              the water it produces?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              In terms of water quality, all of Aquaria’s systems can be
              customized to fit the needs of the individual customer. With the
              use of air filter elements, a basic Aquaria system produces water
              safe for general use. For drinking purposes, Aquaria goes through
              a series of air and water filtration processes that eliminates
              particles of up to 2.5 microns. The air filters remove all the
              dirt and suspended particles in the air before it is even made
              into water. The quality of the water produced exceeds U.S. EPA and
              WHO drinking water standards.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
            <Typography>Does AWG work at all humidity levels?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Aquaria has deployed to many communities around the world with a
              wide range of climates. Aquaria starts the water production
              process from a minimum temperature of 59°F and 40% humidity. The
              higher the humidity and warmer the weather, the higher the
              quantity of water produced per day.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
            <Typography>How is AWG different from a dehumidifier?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              While similar in concept, AWG focuses on producing as much water
              as possible per unit of energy. While dehumidifiers can also
              produce water as a byproduct, it is extremely energy intensive to
              create water as the main product. Using Aquaria’s patented AWG
              technology, we can create up to 5L of water per kWh of energy.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
            <Typography>Does Aquaria come with water storage?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Please check the product specifications individually for more
              details on storage. All Aquaria AWG machines are available with
              optional add-ons as well as customizable options for external
              storage, or we can discuss connecting it to existing storage tanks
              on your premise.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </>
    </div>
  );
}

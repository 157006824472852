import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import logo from "../../assets/water-logo.svg";

const Static = (props) => {
  const { title, OP, SP, TP, FP } = props;

  const data = [{ title: title, OP: OP, SP: SP, TP: TP, FP: FP }];
  return (
    <>
      <Container maxWidth="md">
        <Box sx={{ paddingY: "10%" }}>
          {data.map((item, index) => (
            <Typography
              variant="h2"
              fontWeight={500}
              sx={{
                mt: "50px",
                mb: "20px",
                fontSize: { xl: "90px", lg: "60px", md: "80px", xs: "35px" },
              }}
              key={index}
            >
              <Box sx={{ marginRight: "10px" }}>
                <img alt="" src={logo} width="300px" />
              </Box>
              {item.title}
            </Typography>
          ))}

          <Grid container lg={12} rowGap={5}>
            <Grid item lg={12}>
              {data.map((item, index) => (
                <Typography
                  variant="p"
                  fontSize="26px"
                  fontWeight={400}
                  letterSpacing={0.5}
                  sx={{ mt: "50px", mb: "20px", fontSize: { xs: "18px" } }}
                  key={index}
                >
                  {item.OP}
                </Typography>
              ))}
            </Grid>
            <Grid item lg={12}>
              {data.map((item, index) => (
                <Typography
                  variant="p"
                  fontSize="26px"
                  fontWeight={400}
                  letterSpacing={0.5}
                  sx={{ mt: "50px", mb: "20px", fontSize: { xs: "18px" } }}
                  key={index}
                >
                  {item.SP}
                </Typography>
              ))}
            </Grid>
            <Grid item lg={12}>
              {data.map((item, index) => (
                <Typography
                  variant="p"
                  fontSize="26px"
                  fontWeight={400}
                  letterSpacing={0.5}
                  sx={{ mt: "50px", mb: "20px", fontSize: { xs: "18px" } }}
                  key={index}
                >
                  {item.TP}
                </Typography>
              ))}
            </Grid>
            <Grid item lg={12}>
              {data.map((item, index) => (
                <Typography
                  variant="p"
                  fontSize="26px"
                  fontWeight={400}
                  letterSpacing={0.5}
                  sx={{ mt: "50px", mb: "20px", fontSize: { xs: "18px" } }}
                  key={index}
                >
                  {item.FP}
                </Typography>
              ))}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default Static;

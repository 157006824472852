import { Box, Button, Grid, Paper } from "@mui/material";
import React from "react";
import IMAGE from "../../assets/h2.png";
import tick from "../../assets/check-mark.png";
import FAccordion from "../../Components/FAQ/FaqAccordion";
import Slider from "../test2_slider/Slider";
const FAQ = () => {
  return (
    <>
      <Box
        sx={{
          marginX: "50px",
          marginTop: "50px",
          paddingBottom: "350px",
          borderRadius: "15px",
          textAlign: "Left",
          position: "relative",
        }}
      >
        <Grid container lg={12} spacing={2}>
          {" "}
          <Grid item lg={6}>
            <Box
              sx={{
                display: "block",
                position: "relative",
                margin: "25px",
                marginTop: "20%",
              }}
            >
              <FAccordion />
            </Box>
            <Button
              sx={{
                marginTop: "60px",
                marginLeft: "25px",
                paddingX: "50px",
                paddingY: "20px",
              }}
              variant="contained"
              color="warning"
              href="/contact"
            >
              Learn More
            </Button>
          </Grid>
          <Grid item lg={6} sx={{}}>
            <Box
              sx={{
                p: 2,
                borderRadius: 2,
                fontSize: "0.875rem",
                fontWeight: "700",
                position: "absolute",
                top: 40,
                marginTop: "15%",
                left: "50%",
                zIndex: "tooltip",
              }}
            >
              <Paper
                sx={{
                  borderRadius: "15px",
                  padding: "35px",
                  width: "fit-content",
                  textAlign: "center",
                  justifyContent: "center",
                  border: 2,
                  borderColor: "#F9A825",
                }}
                elevation={6}
              >
                <Box sx={{ paddingTop: "7%", display: "flex" }}>
                  <img
                    alt=""
                    src={tick}
                    style={{ height: "25px", width: "25px" }}
                  />

                  <span
                    style={{
                      paddingLeft: "20px",
                      paddingTop: "3px",
                      fontSize: "16px",
                    }}
                  >
                    The best for every budget
                  </span>
                </Box>
                <Box sx={{ paddingTop: "7%", display: "flex" }}>
                  <img
                    alt=""
                    src={tick}
                    style={{
                      height: "25px",
                      width: "25px",
                    }}
                  />

                  <span
                    style={{
                      paddingLeft: "20px",
                      paddingTop: "3px",
                      fontSize: "16px",
                    }}
                  >
                    Quality work done quickly
                  </span>
                </Box>
                <Box sx={{ paddingTop: "7%", display: "flex" }}>
                  <img
                    alt=""
                    src={tick}
                    style={{ height: "25px", width: "25px" }}
                  />

                  <span
                    style={{
                      paddingLeft: "20px",
                      paddingTop: "3px",
                      fontSize: "16px",
                    }}
                  >
                    Protected payments, every time
                  </span>
                </Box>
                <Box sx={{ paddingTop: "7%", display: "flex" }}>
                  <img
                    alt=""
                    src={tick}
                    style={{ height: "25px", width: "25px" }}
                  />

                  <span
                    style={{
                      paddingLeft: "20px",
                      paddingTop: "3px",
                      fontSize: "16px",
                    }}
                  >
                    24/7 support
                  </span>
                </Box>
              </Paper>
            </Box>
            <Box
              sx={{
                p: 2,
                borderRadius: 2,
                fontSize: "0.875rem",
                fontWeight: "700",
                position: "absolute",
                top: 0,
                left: "50%",
                zIndex: "modal",
              }}
            >
              <img alt="img" src={IMAGE} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default FAQ;

import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: Infinity,
    },
  },
  palette: {
    primary: { 500: "#467fcf" },
    background: {
      paper: "#fff", // dark grey for cards and other components
      default: "#f9fafb", // lighter grey as the main bg of app
    },
  },
  typography: {
    h1: {},
  },
});
export default theme;

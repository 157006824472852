import { Box, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import Q20 from "../../New/mini-components/Q20";
import A1 from "../../../assets/power/ends-2.png"
import A2 from "../../../assets/power/pen.png"
import A3 from "../../../assets/power/insert.png"

const ProductCTA = (props) => {
  const { BG, productImg, subheader, header, subtitle, alert } = props;
  return (
    <>
      <Box
        sx={{
          marginX: "20px",
          marginTop: "50px",
          paddingBottom: "350px",
          borderRadius: "15px",
          textAlign: "Left",
          position: "relative",
        }}
      >
        <Grid container lg={12} spacing={2}>
          {" "}
          <Grid item lg={6} xs={12}>
            <Box
              sx={{
                display: "block",
                position: "relative",
                marginX: "-10%",
                marginRight: "30px",
              }}
            >
              <Typography
                variant="h1"
                fontSize="18px"
                fontWeight={500}
                color="#005096"
                sx={{ paddingBottom: "20px" }}
              >
                {subheader}
              </Typography>
              <Typography
                sx={{
                  paddingBottom: "20px",
                  fontSize: { lg: "40px", md: "35px" },
                }}
                variant="h2"
                fontWeight={500}
                textTransform="uppercase"
              >
                {header}
              </Typography>
              <Box sx={{ paddingRight: "100px" }}>
                <Typography
                  sx={{
                    paddingBottom: "20px",
                    lineHeight: "2.5rem",
                  }}
                  variant="span"
                  fontWeight={300}
                  fontSize="18px"
                >
                  {subtitle}
                </Typography>
              </Box>

              <Grid container lg={10} sx={{ background: "#ededed", marginY:"20px",padding:"5%" }}   >
                
                  <Grid item lg={4}   >
                    <Paper
                      sx={{
                        borderRadius: "15px",
                        width: "fit-content",
                        textAlign: "center",
                        justifyContent: "center",
                        border: 2,
                        borderColor: "#005096",
                        backgroundColor: "transparent",
                        backdropFilter: "blur(4px)",
                      }}
                      elevation={6}
                    >
                      <Box sx={{ paddingTop: "0px" }}>
                        <img
                          alt=""
                          src={A1}
                          width="150px"
                          height="100px"
                        />
                      </Box>
                      <Box>
                      <Typography>Plug-Ends</Typography>
                      </Box>
                    </Paper>
                  </Grid>
                  <Grid item lg={4}>
                  <Paper
                      sx={{
                        borderRadius: "15px",
                        width: "fit-content",
                        textAlign: "center",
                        justifyContent: "center",
                        border: 2,
                        borderColor: "#005096",
                        backgroundColor: "transparent",
                        backdropFilter: "blur(4px)",
                      }}
                      elevation={6}
                    >
                      <Box sx={{ paddingTop: "0px" }}>
                        <img
                          alt=""
                          src={A2}
                          width="150px"
                          height="100px"
                        />
                      </Box>
                      <Box>
                      <Typography>Plug Pen</Typography>
                      </Box>
                    </Paper>
                  </Grid>
                  <Grid item lg={4}>
                  <Paper
                      sx={{
                        borderRadius: "15px",
                        width: "fit-content",
                        textAlign: "center",
                        justifyContent: "center",
                        border: 2,
                        borderColor: "#005096",
                        backgroundColor: "transparent",
                        backdropFilter: "blur(4px)",
                      }}
                      elevation={6}
                    >
                      <Box sx={{ paddingTop: "0px" }}>
                        <img
                          alt=""
                          src={A3}
                          width="150px"
                          height="100px"
                        />
                      </Box>
                      <Box>
                      <Typography>Plug Insert</Typography>
                      </Box>
                    </Paper>
                  </Grid>
                
              </Grid>
              {/*<Box sx={{ marginTop: "0%", textAlign: "left" }}>
                <Q20 alert={alert} />
              </Box>*/}
            </Box>
          </Grid>
          <Grid item lg={6} xs={12}>
            <Box
              sx={{
                p: 0,
                borderRadius: 2,
                fontSize: "0.875rem",
                fontWeight: "700",
                position: "absolute",
                top: "-11%",
                marginTop: "10%",
                left: "45%",
                zIndex: "tooltip",
              }}
            >
              <Paper
                sx={{
                  borderRadius: "15px",
                  width: "fit-content",
                  textAlign: "center",
                  justifyContent: "center",
                  border: 2,
                  borderColor: "#005096",
                  backgroundColor: "transparent",
                  backdropFilter: "blur(4px)",
                }}
                elevation={6}
              >
                <Box sx={{ paddingTop: "0px" }}>
                  <img alt="" src={productImg} width="300px" height="250px" />
                </Box>
              </Paper>
            </Box>
            <Box
              sx={{
                p: 2,
                borderRadius: 2,
                fontSize: "0.875rem",
                fontWeight: "700",
                position: "absolute",
                top: -15,
                left: "55%",
                zIndex: "modal",
                width: "50%",
              }}
            >
              <img
                alt="img"
                src={BG}
                width="110%"
                style={{
                  borderRadius: "20px",
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ProductCTA;

import { Box, Container, Typography } from "@mui/material";
import React from "react";
import logo from "../../assets/water-logo.svg";

const TermComponent = () => {
  return (
    <>
      <Container maxWidth="md">
        <Box sx={{ paddingY: "10%" }}>
          <Typography
            variant="h1"
            fontWeight={500}
            sx={{
              mt: "50px",
              mb: "20px",
              fontSize: { xl: "90px", lg: "60px", md: "80px", xs: "35px" },
            }}
          >
            <Box sx={{ marginRight: "10px" }}>
              <img alt="" src={logo} width="300px" />
            </Box>
            Terms of Use
          </Typography>
          <Box sx={{ marginY: "20px" }}>
            <Typography
              variant="p"
              fontSize="26px"
              fontWeight={400}
              letterSpacing={0.5}
              sx={{ mt: "50px", mb: "20px", fontSize: { xs: "20px" } }}
            >
              By accessing and using our website, you agree to comply with and
              be bound by the following terms and conditions of use:
            </Typography>
          </Box>
          <Box sx={{ marginX: "20px" }}>
            <Typography
              variant="p"
              fontSize="26px"
              fontWeight={400}
              letterSpacing={0.5}
              sx={{
                marginTop: "50px",
                marginBottom: "20px",

                fontSize: { xs: "20px" },
              }}
            >
              1.The content of the pages of our website is for your general
              information and use only. It is subject to change without notice.
            </Typography>
            <Box sx={{ marginY: "20px" }} />
            <Typography
              variant="p"
              fontSize="26px"
              fontWeight={400}
              letterSpacing={0.5}
              sx={{
                paddingTop: "20px",
                fontSize: { xs: "20px" },
              }}
            >
              2.Your use of any information or materials on our website is
              entirely at your own risk, for which we shall not be liable. It
              shall be your own responsibility to ensure that any products,
              services, or information available through our website meet your
              specific requirements.
            </Typography>
            <Box sx={{ marginY: "20px" }} />
            <Typography
              variant="p"
              fontSize="26px"
              fontWeight={400}
              letterSpacing={0.5}
              sx={{
                paddingTop: "20px",
                fontSize: { xs: "20px" },
              }}
            >
              3.Our website may include links to other websites that are not
              controlled by us. We have no responsibility for the content of
              those websites.
            </Typography>
            <Box sx={{ marginY: "20px" }} />
            <Typography
              variant="p"
              fontSize="26px"
              fontWeight={400}
              letterSpacing={0.5}
              sx={{
                paddingTop: "20px",
                fontSize: { xs: "20px" },
              }}
            >
              4.The content on our website is protected by copyright and other
              intellectual property laws. You may not reproduce, distribute, or
              otherwise use any of the content without our prior written
              permission.
            </Typography>
            <Box sx={{ marginY: "20px" }} />
            <Typography
              variant="p"
              fontSize="26px"
              fontWeight={400}
              letterSpacing={0.5}
              sx={{
                paddingTop: "20px",
                fontSize: { xs: "20px" },
              }}
            >
              5.Your use of our website and any dispute arising out of such use
              is subject to the laws of the USA.
            </Typography>
            <Box sx={{ marginY: "20px" }} />
            <Typography
              variant="p"
              fontSize="26px"
              fontWeight={400}
              letterSpacing={0.5}
              sx={{
                paddingTop: "20px",
                fontSize: { xs: "20px" },
              }}
            >
              6.We may change these terms of use at any time without notice by
              updating this page. Your continued use of our website following
              any such changes constitutes your acceptance of the new terms of
              use.
            </Typography>
          </Box>
          <Box sx={{ marginY: "20px" }}>
            <Typography
              variant="p"
              fontSize="26px"
              fontWeight={400}
              letterSpacing={0.5}
              sx={{ mt: "50px", mb: "20px", fontSize: { xs: "20px" } }}
            >
              By using our website, you agree to these terms of use. If you do
              not agree to these terms of use, please do not use our website.
            </Typography>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default TermComponent;

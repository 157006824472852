import { Box, Typography } from "@mui/material";
import React from "react";

const NotFound = () => {
  return (
    <>
      <Box sx={{ marginY: "50%", textAlign: "center" }}>
        <Typography variant="h4">Page Not Found</Typography>{" "}
      </Box>
    </>
  );
};

export default NotFound;

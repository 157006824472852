import { Box, Typography } from '@mui/material'
import React from 'react'
import QuoteBtn from './QuoteBtn'

const HeroContent = () => {
  return (
    <Box>
    <Box sx={{ paddingLeft: { xl:"",lg:"7%",md: "", xs: "" },paddingRight:{xl:"50px",lg:"20%",md:"",sm:"",xs:""},marginTop:{xl:"",lg:"",md:"5%",sm:"25%",xs:"35%"}, }}>
      <Typography 
        variant="h1"
        sx={{
          fontWeight: 600,
          fontSize: { xl: "65px", lg: "60px", md: "48px", xs: "40px" },
          lineHeight: "1.25",
          letterSpacing: "-0.02em",
          textAlign: { xs: "center", md: "left" },
          breakAfter:"auto"
        }}
        color="#01336f"
      >
        Power Cord is a leading producer of cables and electric plugs in Egypt.
      </Typography>
    </Box>
    {/*<Box
      sx={{
        paddingTop: "20px",
        paddingX: {xl:"40%",lg:"10%", md: "25%", xs: "0%" },
      }}
    >
      <Typography
        variant="h2"
        sx={{
          fontWeight: 400,
          fontSize: { xl:"",lg:"20px",md: "24px", xs: "22px" },
          lineHeight: "1.33",
          textAlign: { xs: "center", md: "left" },
        }}
        color="#000"
      >
        Power Cord Company started its activities in the production of all types of cables and electric plugs in Egypt more than four years ago.{" "}
      </Typography>
    </Box>*/}
   
    <Box
      sx={{
        display: { md: "flex", xs: "flex" },
        marginTop: "35px",
      }}
    >
      <Box
        sx={{
          
          marginX: {xl:"",lg:"7%", md: "0px",sm:"30%", xs: "25%" },alignContent:"center",alignItems:"center",justifyContent:"center",justifyItems:"center",
        }}
      >
        <QuoteBtn title="Get A Quote" href="/get-a-quote" />
      </Box>
     
    </Box>
  </Box>
  )
}

export default HeroContent
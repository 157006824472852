import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";

const MenuSingleBtn = (props) => {
  const { href_btn, title } = props;
  const navigate = useNavigate();
  const handleCloseNavMenu = () => {
    navigate(`${href_btn}`);
  };
  return (
    <>
      <Box
        sx={{
          my: 2,
          color: "#005096",
          display: "flex",
          borderRadius: "25px",
          
        }}
      >
        <NavLink
          onClick={handleCloseNavMenu}
          style={{ color: "#005096", textDecoration: "none" }}
          to={href_btn}
        >
          <Typography
            sx={{
              "&:hover": {
                backgroundColor: "#01336f",
                color: "#fff",
                borderRadius: "25px",

                
              },
              fontWeight: {xl:400,lg:400,md:500},
              fontSize: {xl:"17px",lg:"22px",md:'17px'},
              textDecoration: "uppercase",
              paddingX: "20px",

            }}
          >
            {title}
          </Typography>
        </NavLink>
      </Box>
    </>
  );
};

export default MenuSingleBtn;

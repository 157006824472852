import React from 'react'
import Slider from "react-slick";
import { Box } from '@mui/material'
import cable from "../../../assets/power/cable.png"
import braids from "../../../assets/power/braids.png"
import isolates from "../../../assets/power/isolates.png"
import bg from "../../../assets/bgelement.svg";

const products = [
    { id: 1, img: cable, index: "01" },
    { id: 2, img: isolates, index: "02" },
    { id: 4, img: braids, index: "03" },
  ];
const HeroSlider = () => {
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        waitForAnimate: true,
      };
  return (
    <>
         <Box
            sx={{
              marginLeft: "0%",
              display: { xs: "none", md: "block" },
            }}
          >
            <Box sx={{ zIndex: 1, marginTop: "10%", marginLeft: "0%" }}>
              <img alt="" src={bg}  />
            </Box>
            <Box
              elevation={6}
              sx={{
                borderRadius: "25px",
                width: {xl:"",lg:"650px", md: "500px", xs: "200px" },
                padding: "30px",
                marginTop: {xl:"-80%",lg:"-70%",md:"-80%",sm:"",xs:""},
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                justifyItems: "center",
                marginLeft: { md: "0", xs: "0" },
                backgroundColor: "transparent",
              }}
            >
              <Box sx={{ display: { md: "contents", xs: "none" }, zIndex: 2 }}>
                <Slider {...settings}>
                  {products &&
                    products?.map((item) => (
                      <>
                        <Box key={item.id}>
                          <img
                            className="Image"
                            alt=""
                            style={{ width: "500px", marginLeft: "0" }}
                            src={item.img}
                          />
                        </Box>
                      </>
                    ))}
                </Slider>
              </Box>
            </Box>
          </Box>
    </>
  )
}

export default HeroSlider
import { Grid, Typography } from "@mui/material";
import React from "react";
import Faq from "../../Components/FAQ/FAQ";
import Header from "../../Components/header/headerHero";
const FAQ = () => {
  return (
    <>
      <Header
        title="We make secure access to water easy"
        subtitle="Adapting to change, creating solutions, and protecting the water supply for our people and communities."
        img=""
      />

      <Typography
        variant="h1"
        fontWeight="500"
        fontSize="80px"
        textAlign="center"
        letterSpacing={2}
      >
        FAQ
      </Typography>
      <Grid container lg={12} spacing={2}>
        <Faq />
      </Grid>
    </>
  );
};

export default FAQ;

import { Box, Container, Typography } from "@mui/material";
import React from "react";
import logo from "../../assets/water-logo.svg";

const PriceMatchGuarantee = () => {
  return (
    <>
      <Container maxWidth="md" sx={{ display: "block" }}>
        <Box sx={{ paddingY: "10%" }}>
          <Typography
            variant="h1"
            fontWeight={500}
            sx={{
              mt: "50px",
              mb: "20px",
              fontSize: { xl: "90px", lg: "60px", md: "80px", xs: "35px" },
            }}
          >
            <Box sx={{ marginRight: "10px" }}>
              <img alt="" src={logo} width="300px" />
            </Box>
            Price Match Guarantee
          </Typography>

          <Typography
            variant="p"
            fontSize="26px"
            fontWeight={400}
            letterSpacing={0.5}
            sx={{ fontSize: { xs: "20px" } }}
          >
            We are currently offering a price match guarantee on eligible
            atmospheric water generator systems.{" "}
          </Typography>

          <Box sx={{ marginY: "20px" }}>
            <Typography
              variant="h3"
              fontWeight={400}
              sx={{
                mt: "50px",
                mb: "20px",
              }}
            >
              Eligibility{" "}
            </Typography>
            <Typography
              variant="p"
              fontSize="26px"
              fontWeight={400}
              letterSpacing={0.5}
              sx={{ mt: "50px", mb: "20px", fontSize: { xs: "20px" } }}
            >
              Waterwell will review each proposal on a price per liter produced
              basis (At 30C and 80%RH). Your proposal may be eligible for price
              matching if it meets the following requirements.
            </Typography>
          </Box>
          <Box sx={{ marginY: "20px" }}>
            <Typography
              variant="h4"
              fontWeight={500}
              letterSpacing={-0.5}
              sx={{
                mt: "50px",
                mb: "20px",
              }}
            >
              Similar System
            </Typography>
            <Typography
              variant="p"
              fontSize="26px"
              fontWeight={400}
              letterSpacing={0.5}
              sx={{ mt: "50px", mb: "20px", fontSize: { xs: "20px" } }}
            >
              The proposal must offer a system that is of comparable size to the
              Waterwell AWG system you ordered, and have a minimum 3-year
              warranty.
            </Typography>
          </Box>
          <Box sx={{ marginY: "20px" }}>
            <Typography
              variant="h4"
              fontWeight={500}
              letterSpacing={-0.5}
              sx={{
                mt: "50px",
                mb: "20px",
              }}
            >
              Recent Quote
            </Typography>
            <Typography
              variant="p"
              fontSize="26px"
              fontWeight={400}
              letterSpacing={0.5}
              sx={{ mt: "50px", mb: "20px", fontSize: { xs: "20px" } }}
            >
              The competitive quote must be dated within 14 days of order and
              represent the same financing option to be considered (e.g. cash to
              cash, or loan to loan). It cannot include any discounts.
            </Typography>
          </Box>
          <Box sx={{ marginY: "20px" }}>
            <Typography
              variant="h4"
              fontWeight={500}
              letterSpacing={-0.5}
              sx={{
                mt: "50px",
                mb: "20px",
              }}
            >
              Matching Owner Details
            </Typography>
            <Typography
              variant="p"
              fontSize="26px"
              fontWeight={400}
              letterSpacing={0.5}
              sx={{ mt: "50px", mb: "20px", fontSize: { xs: "20px" } }}
            >
              The quote must contain the same name and address used to order
              Waterwell’s AWGs.
            </Typography>
          </Box>
          <Box sx={{ marginY: "20px" }}>
            <Typography
              variant="h3"
              fontWeight={400}
              sx={{
                mt: "50px",
                mb: "20px",
              }}
            >
              Price Match Guarantee Process
            </Typography>
            <Typography
              variant="p"
              fontSize="26px"
              fontWeight={400}
              letterSpacing={0.5}
              sx={{ mt: "50px", mb: "20px", fontSize: { xs: "20px" } }}
            >
              1.Place an order with an Aquaria advisor to begin the fulfillment
              process.
            </Typography>
            <Box sx={{ marginY: "20px" }} />
            <Typography
              variant="p"
              fontSize="26px"
              fontWeight={400}
              letterSpacing={0.5}
              sx={{ mt: "50px", mb: "20px", fontSize: { xs: "20px" } }}
            >
              2.Send the competitive offer to Waterwell by contacting us via the
              Contact us Page on the website.
            </Typography>
            <Box sx={{ marginY: "20px" }} />
            <Typography
              variant="p"
              fontSize="26px"
              fontWeight={400}
              letterSpacing={0.5}
              sx={{ mt: "50px", mb: "20px", fontSize: { xs: "20px" } }}
            >
              3.We will compare your pricing and inform you of eligibility. This
              must be finalized prior to accepting any Payment Details.
            </Typography>
          </Box>

          <Typography
            variant="p"
            fontSize="26px"
            fontWeight={400}
            letterSpacing={0.5}
            sx={{ mt: "50px", mb: "20px", fontSize: { xs: "20px" } }}
          >
            *Waterwell reserves the right to reject any price match requests
            that we believe have been submitted in bad faith. By submitting a
            competitive quote, you agree that Waterwell may contact the
            competitor provider who issued the quote to validate its terms and
            in doing so may have to disclose your name and/or installation
            address. Waterwell may choose to end this program at any time and
            without further notice. Price match offers cannot be assigned to
            other customers and cannot be used in conjunction with any other
            discounts or promotions.{" "}
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export default PriceMatchGuarantee;

import React from "react";
import Header from "../../Components/header/headerHero";
import ProductImg from "../../assets/product_hero_image.png";
import { Box } from "@mui/material";
const Haydropack = () => {
  return (
    <>
      <Header
        title="Hydropack"
        subtitle="Our intelligent device is designed to be powered how you need it. Up to 260+ gallons per day, optionally powered by battery and solar."
        img={ProductImg}
        btnTitle="Secure access to clean water for as little as $0 upfront."
      />
      <Box></Box>
    </>
  );
};

export default Haydropack;

import { Container } from "@mui/material";
import React from "react";
import Static from "../../Components/Static-Pages-Components/Privacy-Component";

const PrivacyPolicy = () => {
  return (
    <>
      <Container maxWidth="lg">
        <Static
          title="Privacy Policy"
          OP="We take your privacy seriously and are committed to protecting your personal information. When you visit our website, we may collect certain information about you, such as your IP address, browser type, and the pages you visit. This information is used to improve our website and provide a better user experience."
          SP="We may also collect personal information that you voluntarily provide to us, such as your name, email address, or other contact information. This information is used to respond to your inquiries, provide you with information about our products and services, and communicate with you about promotions and other marketing materials."
          TP="We will not share your personal information with third parties without your consent, except as required by law or to provide you with the services you have requested. We take appropriate measures to protect your personal information from unauthorized access, use, or disclosure."
          FP="By using our website, you consent to the collection and use of your personal information as described in this privacy policy. If you have any questions or concerns about our privacy practices, please contact us."
        />
      </Container>
    </>
  );
};

export default PrivacyPolicy;

import { Container } from "@mui/material";
import React from "react";
import TermComponent from "../../Components/Static-Pages-Components/Term-Component";

const Terms = () => {
  return (
    <>
      <Container maxWidth="lg">
        <TermComponent />
      </Container>
    </>
  );
};

export default Terms;

import { CloudDoneOutlined } from "@mui/icons-material";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import React from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { NavLink } from "react-router-dom";

const QuoteBtn = (props) => {
  const { type, href, title, onClick, onSubmit } = props;
  return (
    <>
      <Tooltip title="Get A Quote">
        <Button
          onSubmit={onSubmit}
          onClick={onClick}
          type={type}
          startIcon=<CloudDoneOutlined />
          sx={{
            paddingX: "25px",
            paddingY: "10px",
            marginY: "10px",
            borderRadius: "15px",
            backgroundColor: "#01336f",
          }}
          variant="contained"
          endIcon=<NavigateNextIcon />
          size="small"
        >
          <NavLink
            to={href}
            style={{
              color: "#fff",
              textDecoration: "none",
            }}
          >
            <Typography sx={{fontSize:{xl:"30px",lg:"20px",md:"15px",sm:"20px",xs:"14px"}}}>{title}</Typography>
          </NavLink>
        </Button>
      </Tooltip>
    </>
  );
};

export default QuoteBtn;

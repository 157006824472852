import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import MenuNavButton from "./MenuNav";
import MenuSingleBtn from "./MenuSingleBtn";
import NavMenuResponsive from "./NavMenuResponsive";
import logo from "../../assets/logo.svg";

import QuoteBtn from "../New/mini-components/QuoteBtn";
import { NavLink } from "react-router-dom";
import { Divider, Grid, Typography } from "@mui/material";

function ResponsiveAppBar() {
  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: { md: "transparent", xs: "#fff" },
        backdropFilter: "blur(4px)",
        zIndex: "1000",
        height: { xl:"80px",lg:"110px",md: "100px",sm:"100px",xs:"100px" },
        padding: "5px",
      }}
    >
      <Container sx={{maxWidth:{xl:"95%",lg:'79%',md:"100%",sm:"100%",xs:"100%"}}}>
     

        <Toolbar disableGutters>
       <Grid>
          <Box
            sx={{
              marginTop: "10px",
              marginLeft: "20px",
              display: { xl:"flex",lg:"flex",md: "flex",sm:"none", xs: "none" },
            }}
          >
            <NavLink to="/" style={{textDecoration:"none"}}>
              {/*<img alt="" src={logo} width="300px" />*/}
              <Typography variant="h4" sx={{color:"#01336f",fontSize:{xl:"20px",lg:"46px",md:"40px",sm:"",xs:""},fontWeight:"bold"}}>Power Cord</Typography>
              <Divider/>
              <Typography variant="body" sx={{color:"#01336f",fontSize:{xl:"20px",lg:"12px",md:"10px",sm:"",xs:""}}}>for Electrical and Electronic Industries(S.A.E)</Typography>
            </NavLink>
          </Box>
       </Grid>

          <Box sx={{ flexGrow: 2, display: { xs: "flex", md: "none" } }}>
            <NavMenuResponsive />
          </Box>

          <Box
            sx={{
              marginTop: "10px",
              marginLeft: "20px",
              display: { md: "none", xs: "flex" },
            }}
          >
           <NavLink to="/" style={{textDecoration:"none"}}>
              {/*<img alt="" src={logo} width="300px" />*/}
              <Typography variant="h4" sx={{color:"#01336f",fontSize:{xl:"20px",lg:"46px",md:"40px",sm:"40px",xs:"40px"},fontWeight:"bold"}}>Power Cord</Typography>
              <Divider/>
              <Typography variant="body" sx={{color:"#01336f",fontSize:{xl:"20px",lg:"12px",md:"10px",sm:"11px",xs:"11px"}}}>for Electrical and Electronic Industries(S.A.E)</Typography>
            </NavLink>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
             
              justifyContent: "space-evenly",
              marginTop: "10px",
            }}
          >
          
<Grid>
            {/*<MenuSingleBtn title="FAQs" href_btn="/faqs" />*/}
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 1 }} >
          <Grid item ><MenuSingleBtn title="Home" href_btn="/" /></Grid>
          <Grid item ><MenuSingleBtn title="About" href_btn="/about" /></Grid>
          <Grid item ><MenuSingleBtn title="Contact Us" href_btn="/contact" /></Grid>
</Grid>
          </Grid>
          </Box>
          <Grid>

          <Box sx={{ flexGrow: 0, display: { md: "flex", xs: "none" } }}>
            <QuoteBtn title="Get A Quote" href="/get-a-quote" />
          </Box>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;

import React from "react";
import Header from "../../Components/header/headerHero";
import { Box, Typography } from "@mui/material";
import "./style.css";
const Commercial = () => {
  return (
    <>
      <Header
        title="We make secure access to water easy"
        subtitle="Adapting to change, creating solutions, and protecting the water supply for our people and communities."
        img=""
      />
      <Box sx={{ backgroundColor: "#000" }}>
        <Typography variant="h1">This is Header</Typography>
      </Box>
      <div className="HeroSliderLayout">
        <div id="HeroSliderLayout__children"></div>
        <div className="HeroSliderLayout__slider">
          <div className="ImageSlider">
            <div className="ImageSlider__content">
              <div className="ImageSlider__slide ImageSlider__slide-exit-done">
                <img
                  className="Image"
                  alt=""
                  height="930px"
                  width="930px"
                  src="https://craftcloud3d.com/static/a7de62677b955af808a2.png"
                />
              </div>
              <div className="ImageSlider__slide ImageSlider__slide-exit-done">
                <img
                  className="Image"
                  alt=""
                  height="100%"
                  width="100%"
                  src="https://craftcloud3d.com/static/5e2a53b3057d4e31d0dc.png"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Commercial;

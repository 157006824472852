import { Container } from "@mui/material";
import React from "react";
import PriceMatchGuaranteeComponent from "../../Components/Static-Pages-Components/PriceMatchGuarantee";
const PriceMatchGuarantee = () => {
  return (
    <>
      <Container maxWidth="lg" sx={{}}>
        <PriceMatchGuaranteeComponent />
      </Container>
    </>
  );
};

export default PriceMatchGuarantee;

import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";

import q2000 from "../../assets/power/12.png";
import drips from "../../assets/icon_drips.svg";
import grid from "../../assets/icon_grid.svg";
import water from "../../assets/icon_water.svg";

const productAd = [
  {
    id: 1,
    icon: drips,
    title: "ٌFOR ELECTRICAL & ELECTRONIC INDUSTRIES",
    des: "providing  a cable that connects an appliance or device to a mains electricity supply. Power cords come in many different shapes and sizes, and they can be customized to suit specific applications. They are made up of several components, including the conductor, insulation, shielding.",
  },
  {
    id: 2,
    icon: water,
    title: "FOR COMPLEMENTARY INDUSTRIES",
    des: "The term ACI refers to Advanced Complementary Industries, which are industries that work together to create advanced technological products. In recent years, ACI has become increasingly popular in the electric plug-in industry.",
  },
  {
    id: 3,
    icon: grid,
    title: "FOR ADVANCED TECHNICAL INDUSTRIES ",
    des: "The factory takes pride in its ability to provide high-quality products that meet the needs of a wide range of industries. The plastic molds are designed and produced with precision, ensuring that they are both durable and efficient.Similarly, the sheet metal molds are carefully crafted to meet the specific requirements of each client.",
  },
];

const PASection = () => {
  return (
    <>
      <Container maxWidth="xl">
        <Box
          sx={{
            backgroundColor: "#ddd",
            marginLeft: { lg: "20%", md: "5%", xs: "0" },
            marginRight: { lg: "20%", md: "5%", xs: "0" },
            marginTop: "5%",
            paddingTop: "5%",
            paddingBottom: "3%",
            position: "relative",
          }}
        >
          <Box
            sx={{
              marginBottom: "5%",
              marginLeft: { md: "10%", xs: "25px" },
              marginRight: { md: "5%", xs: "25px" },
            }}
          >
            <Typography
              variant="h2"
              fontWeight={600}
              lineHeight="1.2em"
              sx={{
                fontWeight: 500,
                fontSize: { md: "35px", xs: "35px" },
                lineHeight: "1.3",
                letterSpacing: "0.03em",
                textAlign: { xs: "left", md: "left" },
              }}
            >
              <span style={{ color: "#005096" }}>
              Plugin Industries are a vital part of the global economy,
              </span>{" "}
               driving innovation and progress in a wide range of fields. By embracing the latest technology and working collaboratively with others, they are helping to create a brighter future for us all.
            </Typography>
          </Box>
          <Grid container lg={12} spacing={2} sx={{}}>
            <Grid item lg={8}>
              <Box sx={{ marginLeft: { lg: "10%", md: "6%", xs: "0" } }}>
                {productAd &&
                  productAd?.map((item) => (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          padding: { md: "30px", lg: "10px", xs: "20px" },
                        }}
                      >
                        <Box sx={{ paddingX: "10px" }} key={item.id}>
                          <img alt="" src={item.icon} width="60px" />
                        </Box>
                        <Box sx={{}}>
                          <Typography
                            variant="h4"
                            sx={{ fontSize: { lg: "28px", xs: "25px" } }}
                          >
                            {item.title}
                          </Typography>
                          <Box
                            sx={{
                              marginRight: { lg: "10%", md: "20%", xs: "19%" },
                            }}
                          >
                            <Typography variant="p">{item.des}</Typography>
                          </Box>
                        </Box>
                      </Box>
                    </>
                  ))}
              </Box>
            </Grid>
            <Grid item lg={4}>
              <Box
                sx={{
                  marginLeft: { lg: "50px", md: "150px" },
                  paddingTop: { lg: "70%", md: "5%" },

                  display: { md: "flex", xs: "none" },
                }}
              >
                <img alt="" src={q2000} width="550px" />
              </Box>
              <Box
                sx={{
                  marginLeft: "80px",
                  marginBottom: "-20px",
                  display: { md: "none", xs: "flex" },
                }}
              >
                <img alt="" src={q2000} width="300px" />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default PASection;

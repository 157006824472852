import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";

import Divider from "@mui/material/Divider";

import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import MenuIcon from "@mui/icons-material/Menu";
import { IconButton, Typography } from "@mui/material";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import logo from "../../assets/logo.svg";
import QuoteBtn from "../New/mini-components/QuoteBtn";
import { NavLink } from "react-router-dom";

export default function TemporaryDrawer() {
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
     {/* <Box sx={{ display: "flex", ml: "20px" }}>
        <AutoAwesomeMotionIcon fontSize="small" color="inherit" />
        <Typography
          variant="h6"
          noWrap
          component="a"
          sx={{
            mr: 2,
            display: { xs: "Flex", md: "none" },
            fontFamily: "inherit",
            fontWeight: 500,
            letterSpacing: ".05rem",
            color: "#3E608F",
            textDecoration: "none",
            textAlign: "Left",
            ml: "15px",
            fontSize: "18px",
          }}
        >
          Main
        </Typography>
      </Box>*/}
      <Box sx={{ }}>
        <ListItemButton href="/">
          <ListItemIcon sx={{ ml: "10px" }}>
            <ArrowCircleRightIcon fontSize="small" color="inherit" />
          </ListItemIcon>
          <ListItemText fontSize="15px" primary="Home"  sx={{ ml: "-25px",color:"#3E608F" }} />
        </ListItemButton>
      </Box>
      <Divider />
      <Box sx={{  }}>
        <ListItemButton href="/about">
          <ListItemIcon sx={{ ml: "10px" }}>
            <ArrowCircleRightIcon  fontSize="small" color="inherit" />
          </ListItemIcon>
          <ListItemText fontSize="15px" primary="About"  sx={{ ml: "-25px",color:"#3E608F" }} />
        </ListItemButton>
      </Box>
      <Divider />
      <Box sx={{ }}>
        <ListItemButton href="/contact">
          <ListItemIcon sx={{ ml: "10px" }}>
            <ArrowCircleRightIcon fontSize="small" color="inherit" />
          </ListItemIcon>
          <ListItemText
            fontSize="15px" primary="Contact"  sx={{ ml: "-25px",color:"#3E608F" }}
          />
        </ListItemButton>
      </Box>

      <Divider />
    </Box>
  );

  return (
    <Box>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={toggleDrawer(anchor, true)}
          >
            <MenuIcon sx={{ color: "#000", }} />
          </IconButton>
          <Drawer
            sx={{ backdropFilter: "blur(4px)" }}
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            <Box>
              <Box
                sx={{
                  display: "flex",
                  margin: "20px",
                  paddingBottom: "20px",
                  paddingTop: "10px",
                }}
              >
                <Box
                  sx={{
                    marginTop: "10px",
                    marginLeft: "0px",
                    marginRight: "10%",
                    display: { md: "none", xs: "flex" },
                  }}
                >
                   <NavLink to="/" style={{textDecoration:"none"}}>
              {/*<img alt="" src={logo} width="300px" />*/}
              <Typography variant="h4" sx={{color:"#01336f",fontSize:{xl:"20px",lg:"46px",md:"40px",sm:"35px",xs:""},fontWeight:"bold"}}>Power Cord</Typography>
              <Divider/>
              <Typography variant="body" sx={{color:"#01336f",fontSize:{xl:"20px",lg:"12px",md:"10px",sm:"9px",xs:""}}}>for Electrical and Electronic Industries(S.A.E)</Typography>
            </NavLink>
                </Box>
              </Box>
              {list(anchor)}

              <Box sx={{ mt: "25px", paddingY: "10px", marginX: "20px" }}>
                <QuoteBtn title="Get a Quote" href="/get-a-quote" />
              </Box>
            </Box>
          </Drawer>
        </React.Fragment>
      ))}
    </Box>
  );
}

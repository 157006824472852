import React from "react";
import Header from "../../Components/header/headerHero";

const NewDirections = () => {
  return (
    <>
      <Header
        title="We make secure access to water easy"
        subtitle="Adapting to change, creating solutions, and protecting the water supply for our people and communities."
        img=""
      />
    </>
  );
};

export default NewDirections;

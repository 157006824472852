import { Box, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import Timeline from "../../Components/New/Timeline";
import Radko from "../../assets/Radko.jpg";
import Filip from "../../assets/Filip.jpg";
import Ales from "../../assets/ales.jpg";
import Ahmed from "../../assets/dr-ahmed.jpg";
import Richard from "../../assets/Richard.jpg";
import logo from "../../assets/power/powercode.png";
import { Footer, Header } from "../../Components";
import Image from "../../assets/power/electrical.jpg"
import Leadership from "../../Components/Leadership/Leadership";
const styles = {
  paperContainer: {
      backgroundImage: `url(${Image})`,
     
      
  }
};
const data = [
  {
    id: 1,
    img: Ahmed,
    name: "Dr. Ahmed Faisal",
    title: `Chairman of the Board and Managing Director`,
  },
  {
    id: 2,
    img: Richard,
    name: `Mr. Amr Roshdy Khedr`,
    title: `Member of the Board of Directors`,
  },
  
];
const About = () => {
  return (
    <>
    <Grid container lg={12} > 
      <Box
       style={styles.paperContainer}
        sx={{
          backgroundColor: "#ddd",
          height: "auto",
          padding: "60px",
          width: "auto",
          paddingTop: { lg: "0", md: "5%", xs: "10%" },
        marginTop:{ lg: "5%", md: "9%", xs: "12%"},
        
        
        }}
      >
        <Box
          sx={{
            display: { md: "flex", xs: "flex" },
            padding: { md: "60px" },
          }}
        >
          <Grid container lg={12}  >
            <Grid item lg={7}>
              <Box sx={{ paddingRight: { md: "25%", xs: "5%" } }}>
                <Typography
                  variant="h1"
                  sx={{
                    fontWeight: 600,
                    fontSize: { md: "60px", xs: "35px" },
                    lineHeight: "1.25",
                    letterSpacing: "-0.02em",
                    textAlign: { xs: "left", md: "left" },
                    paddingBottom: "20px",
                  }}
                  color="#fff"
                >
                  We are the future of atmospheric water generators
                </Typography>
                <Typography
                  variant="p"
                  sx={{
                    marginRight: { md: "30px" },
                    fontWeight: 400,
                    fontSize: { md: "20px", xs: "17px" },
                    lineHeight: "1.5",
                    textAlign: { xs: "left", md: "left" },
                  }}
                  color="#fff"
                >
                  Power Cord Company is one of the top three companies in Egypt producing cables and electric plugs. They have been in operation for over four years.
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={5} sx={{}}>
              <Box
                sx={{
                  padding: "30px",
                  marginTop: "0%",
                  marginLeft: { md: "0px", lg: "0" },
                  display: { md: "flex", xs: "none" },
                }}
              >
                <img alt="" src="" width="500px" />
              </Box>
    </Grid>
            </Grid>
        </Box>
      </Box>
          </Grid>
      <Box
        sx={{
          height: { md: "130vh", lg: "130vh", xs: "100vh" },
          padding: { md: "60px", xs: "0" },
        }}
      >
        <Box sx={{ padding: { md: "60px", xs: "0" } }}>
          <Box sx={{ display: { md: "flex", xs: "none" } }}>
            <Typography variant="h3">Timeline</Typography>
          </Box>
          <Box>
            <Timeline />
          </Box>
          
        </Box>

      <Box
        sx={{
          backgroundColor: "#ddd",
          height: "55vh",
          padding: { md: "60px", xs: "0" },
          
          marginTop: {lg:"", xs: "35%", md: "0" },
        }}
      >
     
        <Box sx={{ padding: { md: "60px", xs: "0" } }}>
          <Box
            sx={{
              paddingLeft: {lg:"0%", xs: "35px", md: "0" },
              paddingTop: { xs: "35px", md: "0" },
            }}
          >
         
          <Box sx={{ textAlign: "center"  }}>
          <Typography variant="h3" sx={{paddingBottom:"35px"}} >Leadership</Typography>
          <Typography variant="h5">
            Our founders are experienced investors and entrepreneurs with a
            track record in innovation.{" "}
          </Typography>
        </Box>
          </Box>
          <Box sx={{ padding: "20px", marginY: "20px",paddingLeft:"0%" }}>
            <Grid
              container
              lg={12}
              spacing={2}
              sx={{ justifyContent: "space-around" }}
            >
              {data &&
                data?.map((item) => (
                  <>
                    <Grid item lg={6} xs={12} key={item.id}>
                      <Paper
                        sx={{
                          backgroundColor: "#fff",
                          textAlign: "center",
                          borderRadius: "18px",
                          padding: "20px",
                          margin: "20px",
                        }}
                        elevation={6}
                      >
                        <Box sx={{ textAlign: "center", marginTop: "20px" }}>
                          <Typography
                            variant="h3"
                            sx={{ fontSize: "25px", fontWeight: 500 }}
                          >
                            {item.name}
                          </Typography>
                          <Box sx={{ marginY: "10px" }}>
                            <Typography variant="h3" sx={{ fontSize: "20px" }}>
                              {item.title}
                            </Typography>
                          </Box>
                        </Box>
                      </Paper>
                    </Grid>
                  </>
                ))}
            </Grid>
          </Box>
        </Box>
      </Box>
      </Box>
      {/*<Box sx={{ height: "80vh", padding: "60px" }}>
  <Box sx={{ padding: "60px", textAlign: "center" }}>
    <Box>
      <Typography variant="h3">Global Service</Typography>
    </Box>
    <Box></Box>
  </Box>
</Box>
  */}
    </>
  );
};

export default About;

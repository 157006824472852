import { Box, Typography } from "@mui/material";
import React from "react";
import ProductQ20 from "../CTA/custom/ProductQ20";
import ProductQ1000 from "../CTA/custom/ProductQ1000";
import ProductQ2000 from "../CTA/custom/ProductQ2000";

import womenWater from "../../assets/power/top.png";
import home from "../../assets/power/aci.png";
import House from "../../assets/power/electrical.jpg";
import Solar from "../../assets/power/powercode.png";
import Slider from "react-slick";
import qw2000 from "../../assets/power/atic.png";
import natural from "../../assets/power/ai.png";

const HelpingSolutions = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    waitForAnimate: true,
    arrow: true,
  };
  return (
    <>
      <Box
        sx={{
          backgroundColor: "#ddd",
          height: { lg: "120vh", md: "70vh", xs: "65vh" },
          zIndex: 0,
          position: "relative",
        }}
      >
        <Box sx={{ paddingTop: { md: "5%", xs: "20%" }, zIndex: 2 }}>
          <Typography
            variant="h2"
            textAlign="center"
            sx={{
              fontWeight: 600,
              fontSize: { xs: "45px" },
            }}
          >
            Transforming the {""}
            <span style={{ color: "#005096" }}> Plugin </span> Concept
          </Typography>
        </Box>
        <Box
          sx={{
            marginX: { lg: "20%", md: "15%", xs: "3%" },
            marginY: { lg: "2%", md: "2%", xs: "5%" },
            justifyContent: "center",
            justifyItems: "center",
            alignContent: "center",
            alignItems: "center",
            paddingX: "2%",
            textAlign: "center",
          }}
        >
          <Typography
            variant="p"
            textAlign="center"
            sx={{ fontWeight: 400, fontSize: "20px" }}
          >
           In today's world, plugin industries are leading the way when it comes to the latest technology. These industries are constantly pushing the boundaries of what is possible, and finding new and innovative ways to improve our lives. From cutting-edge medical devices to advanced automotive technologies, plugin industries are at the forefront of the tech revolution.          </Typography>
        </Box>
        <Box
          sx={{
            justifyContent: "center",
            justifyItems: "center",
            alignContent: "center",
            alignItems: "center",
            textAlign: "center",
            backgroundColor: "#fff",
            borderRadius: "25px",
            marginX: { lg: "30px", md: "30px" },
            marginY: { lg: "0px", md: "80px" },

            height: "32vw",
            border: "solid 2px #005096 ",
            display: { md: "block", xs: "none" },
          }}
        >
          <Slider {...settings}>
            <Box
              sx={{
                paddingX: { md: "8%", lg: "8%" },
                position: "relative",
              }}
            >
              <ProductQ1000
                BG={House}
                productImg={Solar}
                header="Electrical & Electronic Industries"
                subtitle="The electrical and electronic industries play a vital role in modern society. From powering our homes to facilitating global communication, these industries have revolutionized the way we live and work. They encompass a wide range of products and technologies, including computers, smartphones, televisions, household appliances, and renewable energy systems."
              />
            </Box>
            <Box
              sx={{
                paddingX: { md: "10%", lg: "8%" },
                position: "relative",
              }}
            >
              <ProductQ2000
                BG={natural}
                productImg={qw2000}
                header="Advanced Technical Industries"
                subtitle="The factory manufactures plastic molds and sheet metal molds, follows machine spare parts, and develops production lines and all electrical panels.
."
              />
            </Box>
            <Box
              sx={{ paddingX: { md: "10%", lg: "8%" }, position: "relative" }}
            >
              <ProductQ20
                BG={womenWater}
                productImg={home}
                header="Complementary Industries"
                subtitle="For industries that complement each other."
              />
            </Box>
          </Slider>
        </Box>
      </Box>
    </>
  );
};

export default HelpingSolutions;

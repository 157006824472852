import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import CircleIcon from "@mui/icons-material/Circle";
import { useState } from "react";
import Slider from "react-slick";

const HeaderHero = (props) => {
  const { img, title, subtitle, btnTitle } = props;
  const [over, setOver] = useState(false);

  const handleMouseEnter = () => {
    setOver(true);
  };
  const handleMouseLeave = () => {
    setOver(false);
  };

  const settings = {
    infinite: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
  };

  const bgSettings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
  };
  return (
    <>
      <Box
        sx={{
          backgroundColor: "#50858B",
          marginTop: "-100px",
          marginBottom: "15%",
          zIndex: 0,
          height: { lg: "70vh", md: "40vh", sm: "40vh", xs: "40vh" },
        }}
      >
        <Grid container lg={12} sx={{ paddingTop: "10%", paddingX: "5%" }}>
          <Grid item lg={8}>
            <Box>
              <Typography variant="h1">{title}</Typography>
              <Typography
                sx={{
                  fontFamily: "klarheit_grotesk_semibold",
                  fontStyle: "normal",
                  lineHeight: 1.5,
                  fontSize: "24px",
                  marginBottom: "30px",
                  lineBreak: "loose",
                  paddingRight: "20%",
                }}
                fontWeight={300}
                variant="h5"
              >
                {subtitle}
              </Typography>
              <Button
                style={{
                  borderRadius: "20px",
                  backgroundColor: over ? "#000" : "#fff",
                  border: "solid 2px #000",
                  cursor: "pointer",
                  padding: "20px",
                  color: over ? "#fff" : "#000",
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <CircleIcon
                  sx={{ marginRight: "10px" }}
                  backgroundColor="#4cbb17"
                  color="success"
                />
                {btnTitle}

                <ArrowCircleRightIcon sx={{ marginLeft: "20px" }} />
              </Button>
            </Box>
          </Grid>
          <Grid item lg={4}>
            <Paper
              elevation={6}
              sx={{
                borderRadius: "25px",
                width: "100%",
                padding: "20px",
                marginTop: "15%",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                justifyItems: "center",
                marginLeft: "-15%",
              }}
            >
              <Box>
                <Slider {...settings}>
                  <Box
                    sx={{
                      backgroundColor: "#fff",
                      border: "#000 solid 2px",
                      borderRadius: "20px",
                    }}
                  >
                    <img
                      {...bgSettings}
                      className="Image"
                      alt=""
                      style={{ width: "300px", marginLeft: "15%" }}
                      src="https://craftcloud3d.com/static/a7de62677b955af808a2.png"
                    />
                  </Box>
                  <Box
                    sx={{
                      backgroundColor: "#000",
                      border: "#fff solid 2px",
                      borderRadius: "20px",
                    }}
                  >
                    <img
                      className="Image"
                      alt=""
                      style={{ width: "300px", marginLeft: "15%" }}
                      src="https://craftcloud3d.com/static/5e2a53b3057d4e31d0dc.png"
                    />
                  </Box>
                </Slider>
              </Box>
            </Paper>
          </Grid>
          <Grid item lg={4}>
            <Box></Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default HeaderHero;
